<template>
    <div class="body" id="ygh-content-box">
        <x-card class="roleSearch" v-if="hasPerm('sysRole:page')">
            <div slot="content" class="table-page-search-wrapper">
                <a-form layout="inline">
                    <a-row :gutter="48">
                        <a-col :md="8" :sm="24">
                            <a-form-item label="角色名">
                                <a-input v-model="queryParam.name" allow-clear placeholder="请输入角色名" />
                            </a-form-item>
                        </a-col>
                        <a-col :md="8" :sm="24">
                            <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
                            <a-button style="margin-left: 8px" @click="resetData">重置</a-button>
                        </a-col>
                    </a-row>
                </a-form>
            </div>
        </x-card>
        <a-card :bordered="false" style="height: calc(100% - 80px)">
            <s-table ref="table" :columns="columns" :data="loadData" :alert="false" :rowKey="(record) => record.id">
                <template slot="operator" v-if="hasPerm('sysRole:add') && false">
                    <a-button @click="$refs.addForm.add()" icon="plus" type="primary" v-if="hasPerm('sysRole:add')"
                        >新增角色</a-button
                    >
                </template>

                <!-- <span slot="action" slot-scope="text, record" v-if="record.isSystem == 0 || userInfo.account == 'superAdmin'"> -->
                <span slot="action" slot-scope="text, record">
                    <template v-if="record.isSystem != 1">
                        <a v-if="hasPerm('sysRole:edit')" @click="$refs.editForm.edit(record)">编辑</a>
                        <a-divider type="vertical" v-if="hasPerm('sysRole:edit')" />
                    </template>

                    <a-dropdown
                        v-if="hasPerm('sysRole:grantMenu') || hasPerm('sysRole:grantData') || hasPerm('sysRole:delete')"
                    >
                        <a class="ant-dropdown-link"> 更多 <a-icon type="down" /> </a>
                        <a-menu slot="overlay">
                            <a-menu-item v-if="hasPerm('sysRole:grantMenu')">
                                <a @click="selectPersonAndPart(record)">授权用户</a>
                            </a-menu-item>
                            <a-menu-item v-if="record.isSystem == 0 || userInfo.account == 'superAdmin'">
                                <a @click="$refs.roleMenuForm.roleMenu(record)">授权菜单</a>
                            </a-menu-item>
                            <a-menu-item v-if="record.isSystem == 0 || userInfo.account == 'superAdmin'">
                                <a @click="$refs.roleOrgForm.roleOrg(record)">授权数据</a>
                            </a-menu-item>
                            <a-menu-item v-if="record.isSystem == 0 || userInfo.account == 'superAdmin'">
                                <a-popconfirm
                                    placement="topRight"
                                    title="确认删除？"
                                    @confirm="() => sysRoleDelete(record)"
                                >
                                    <a>删除</a>
                                </a-popconfirm>
                            </a-menu-item>
                        </a-menu>
                    </a-dropdown>
                </span>

                <template slot="isSystem" slot-scope="text, record">
                    <span v-if="record.isSystem == 0">自定义</span>
                    <span v-else>系统内置</span>
                </template>

                <template slot="manage" slot-scope="text, record">
                    <a-tag class="tags" color="blue" @click="$refs.userTabel.open(record)"> 查看授权用户</a-tag>
                    <!-- <div v-for="(o, i) in userList" :key="'userList' + i" class="my-item">
                              <a-tag class="tags flex0" color="blue">
                                <OpenData :type="o.type" :openid="o.userId || o.departmentId" :corpid="o.corpId"></OpenData>
                              </a-tag>
                            </div> -->
                </template>
            </s-table>

            <user-tabel ref="userTabel" @ok="handleOk" />
            <add-form ref="addForm" @ok="handleOk" />
            <edit-form ref="editForm" @ok="handleOk" />
            <role-menu-form ref="roleMenuForm" @ok="handleOk" />
            <role-org-form ref="roleOrgForm" @ok="handleOk" />
        </a-card>
    </div>
</template>

<script>
import { allocateUser, getRolePage, sysRoleDelete } from '@/api/modular/system/roleManage'
import { STable, XCard } from '@/components'
import { handleAppointEleHeight } from '@/utils/common.js'
import OpenData from '@/views/fileStream/documentManage/weixin/openData'
import selectPersonAndDepart, {
    dealResult,
    getUserListMsg,
} from '@/views/fileStream/documentManage/weixin/selectPerson'
import { init_wx } from '@/views/fileStream/documentManage/weixin/weixin'
import { mapGetters } from 'vuex'
import addForm from './addForm'
import editForm from './editForm'
import roleMenuForm from './roleMenuForm'
import roleOrgForm from './roleOrgForm'
import userTabel from './userTabel'
export default {
    components: {
        OpenData,
        XCard,
        STable,
        addForm,
        editForm,
        userTabel,
        roleMenuForm,
        roleOrgForm,
    },

    data() {
        return {
            // 查询参数isSystem
            queryParam: {
                // isSystem: 1,
            },
            // 表头
            columns: [
                {
                    title: '角色名',
                    dataIndex: 'name',
                },
                {
                    title: '角色类型',
                    dataIndex: 'isSystem',
                    scopedSlots: { customRender: 'isSystem' },
                },
                {
                    title: '备注',
                    dataIndex: 'remark',
                },
                {
                    title: '授权用户',
                    dataIndex: 'manage',
                    scopedSlots: { customRender: 'manage' },
                },
            ],
            // 加载数据方法 必须为 Promise 对象
            loadData: (parameter) => {
                return getRolePage(Object.assign(parameter, this.queryParam)).then((res) => {
                    handleAppointEleHeight('#ygh-content-box', ['.s-table-tool', '.roleSearch'], 66, res)
                    return res.data
                })
            },
            userList: [],
        }
    },
    computed: {
        ...mapGetters(['admintype', 'userInfo']),
    },
    created() {
        init_wx().catch((err) => {
            this.$message.error('微信配置失败' + err)
        })
        if (
            this.hasPerm('sysRole:edit') ||
            this.hasPerm('sysRole:grantMenu') ||
            this.hasPerm('sysRole:grantData') ||
            this.hasPerm('sysRole:delete')
        ) {
            this.columns.push({
                title: '操作',
                width: '150px',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' },
            })
        }
    },
    mounted() {
        handleAppointEleHeight('#ygh-content-box', ['.s-table-tool', '.roleSearch'], 66)
    },
    methods: {
        sysRoleDelete(record) {
            sysRoleDelete(record)
                .then((res) => {
                    if (res.success) {
                        this.$message.success('删除成功')
                        this.$refs.table.refresh()
                    } else {
                        this.$message.error('删除失败：' + res.message)
                    }
                })
                .catch((err) => {
                    this.$message.error('删除错误：' + err.message)
                })
        },

        handleOk() {
            this.$refs.table.refresh()
        },
        // 打开企微通讯录 // mode选择模式，single表示单选，multi表示多选
        selectPersonAndPart({ id }) {
            const {
                selectedOpenUserIds,
                selectedCorpGroupUserIds,
                selectedDepartmentIds,
                selectedCorpGroupDepartmentIds,
            } = getUserListMsg()
            selectPersonAndDepart({
                selectedOpenUserIds,
                selectedCorpGroupDepartmentIds,
                selectedDepartmentIds,
                selectedCorpGroupUserIds,
            }).then((res) => {
                const { result, resPart } = dealResult(res)
                this.userList = [...result, ...resPart]
                this.allocateUser({ userIdList: [...result, ...resPart], roleId: id })
            })
        },
        // 授权用户
        allocateUser({ userIdList = [], roleId = 0 }) {
            if (userIdList.length > 0) {
                userIdList = userIdList.map((item) => item.userId)
                allocateUser(Object.assign({ userIdList, roleId })).then((res) => {
                    if (res.success) {
                        this.$message.success('分配成功')
                        this.$refs.table.refresh()
                    } else {
                        this.$message.error('分配失败：' + res.message)
                    }
                })
            }
        },
        //
        resetData() {
            this.queryParam = {}
            this.$refs.table.refresh()
        },
    },
}
</script>

<style lang="less" scoped>
.table-operator {
    margin-bottom: 18px;
}

button {
    margin-right: 8px;
}

.body {
    // width: 1280px;
    width: 90%;
    margin: auto !important;
    height: calc(100vh - 120px);
}

.flex0 {
    display: flex;
    align-items: center;
}

.tags {
    cursor: pointer;
}
</style>
